import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import _ from "lodash";

import Layout from "@components/Layout";
import useQuery from "@hooks/useQuery";

import SearchWidget from "@components/SearchWidget";

const SearchPage = ({ data }) => (
    <SearchPageTemplate data={data} />
)

const SearchPageTemplate = ({ data }) => {
    const { keyword } = useQuery();
    const intl = useIntl();
    const { entity } = data.markdownRemark.frontmatter;

    return (
        <Layout customTitle={intl.formatMessage({id: "SearchPage_PAGETITLE"}, {keyword, entity})} isHeaderFixed isFooterFixed headerSearchValue={keyword}>
            <SearchWidget entity={entity}/>
        </Layout>
      );
}

export default SearchPage;

export const searchPageQuery = graphql `
  query SearchPage($id: String!) {
    markdownRemark(id: {eq: $id}) {
        frontmatter {
            entity
        }
    }
}
`
